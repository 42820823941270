//mobile 700px, tablet 900px, small monitor 1440px

$breakpoints-up: (
  "medium": 43.75em,
  "large": 56.2em,
  "xlarge": 90em,
);

//699.98, 899.98, 1349.98
$breakpoints-down: (
  "small": 43.7485em,
  "medium": 56.24875em,
  "large": 89.99875em,
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}
