@forward "globals";
@use "../styles/globals/breakpoints" as b;

$sidewith: 100%;
$border: 1px solid white;
$borderrad: 2px 10px 20px;
$bcolor: hsl(0, 0%, 11%);
$navshadow: hsl(0, 0%, 40%);
$textpadleft: 8px;
$mobileborderrad: 10px;

.grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 5fr;
  padding: 5px;
  gap: 1rem;
  height: 100vh;

  @include b.breakpoint-down(small) {
    display: flex;
    flex-direction: column;
  }

  &__header {
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    width: $sidewith;
    border: $border;
    border-radius: $borderrad;
    padding: 5px;
    padding-left: $textpadleft;

    @include b.breakpoint-down(small) {
      text-align: center;
      border-radius: $mobileborderrad;
      font-size: small;
    }

    &__button {
      background-color: inherit;
      color: inherit;
      border-radius: 20px;
      width: 70%;
      height: 100%;
      border-color: rgb(176, 191, 202);
      box-shadow: 0px 0px 3px 1px rgb(127, 126, 126),
        inset 0px 0px 1px 3px rgb(90, 87, 87);
      text-align: center;
      padding-top: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-family: "Lato", sans-serif;

      @include b.breakpoint-down(small) {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
      }

      &:hover {
        border-color: $bcolor;
        cursor: pointer;
      }

  
      transition: border-color 500ms;
    }


    transition: box-shadow 500ms;
    transition: color 500ms;
    transition: background-color 500ms;
  }

  &__nav {
    grid-column: 1 / 1;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    width: $sidewith;
    border: $border;
    border-radius: $borderrad;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      box-shadow: 0px -0px 10px 2px $navshadow;
    }

    transition: box-shadow 500ms;

    @include b.breakpoint-down(small) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      border-radius: $mobileborderrad;
      gap: 2rem;
    }
  }

  &__nav__item {
    width: 100%;
    padding: 5px;
    text-decoration: underline;
    text-decoration-color: rgb(255, 255, 255, 0);
    user-select: none;

    &:hover {
      text-decoration-color: rgb(255, 255, 255, 255);
      cursor: pointer;
    }

    transition: text-decoration-color 500ms;
  }

  &__main {
    grid-column: 2/2;
    grid-row: 1/3;
    height: 100%;

    @include b.breakpoint-down(small) {
      line-height: 1.7rem;
    }

    &__webgl {
      height: 100%;
    }

    &__texts__about {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;

      &__copyright {
        position: fixed;
        top: 97%;
        font-size: 0.7rem;

        @include b.breakpoint-down(small) {
          position: relative;
          top: auto;
        }
      }
    }

    &__texts__audio {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;

      & > ul > iframe {
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        @include b.breakpoint-down(small) {
          display: none;
        }
      }
    }

    &__texts__video {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;

      &__frame {
        @include b.breakpoint-down(small) {
          display: none;
        }
      }
    }

    &__texts__interactiveart {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;

      @include b.breakpoint-down(small) {
        line-height: 1.35rem;
      }
    }

    &__texts__fogracer {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;

      &__frame {
        @include b.breakpoint-down(small) {
          display: none;
        }
      }
    }

    &__texts__websynthesizer {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 100%;
      flex-direction: column;
      gap: 1rem;
      padding-top: 2%;

      &__frame {
        background-color: white;
        @include b.breakpoint-down(small) {
          display: none;
        }
      }
    }
  }
}
