@use "./breakpoints" as b;

html {
  box-sizing: border-box;
  font-size: 100%;
  text-underline-offset: 2px;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

h2 {
  font-size: small;
}

li {
  list-style: none;
}

a {
  padding: 5px;
  color: white;
  text-decoration: underline;
  text-decoration-color: rgb(255, 255, 255, 0);
  user-select: none;

  &:hover {
    cursor: pointer;
    text-decoration-color: rgb(255, 255, 255, 255);
  }

  transition: text-decoration-color 500ms;

  @include b.breakpoint-down(small) {
    text-decoration-color: rgb(255, 255, 255, 255);
  }
}
